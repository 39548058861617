<template>
  <v-menu v-model="menu" min-width="500px" offset-y :close-on-content-click="false" :close-on-click="!loading">
    <template v-slot:activator="{ on: onMenu, attrs: attrsMenu}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
<!--          <v-hover v-slot="{ hover }">-->
<!--            <span>-->
<!--              <v-btn-->
<!--                  v-if="item.status_name === 'active'"-->
<!--                  icon v-bind="attrs" v-on="{on, ...onMenu, ...attrsMenu}">-->
<!--                <v-icon v-if="!hover" color="green">mdi-play-circle</v-icon>-->
<!--                <v-icon v-if="hover" color="red">mdi-pause</v-icon>-->
<!--              </v-btn>-->

<!--              <v-btn-->
<!--                  v-if="item.status_name === 'paused'"-->
<!--                  icon v-bind="attrs" v-on="{on, ...onMenu, ...attrsMenu}">-->
<!--                <v-icon v-if="hover" color="green">mdi-play-circle</v-icon>-->
<!--                <v-icon v-if="!hover" color="red">mdi-pause</v-icon>-->
<!--              </v-btn>-->
<!--            </span>-->
<!--          </v-hover>-->

            <span>
              <v-btn
                  v-if="item.status_name === 'active'"
                  icon v-bind="attrs" v-on="{on, ...onMenu, ...attrsMenu}">
                <v-icon color="green">mdi-play-circle</v-icon>
              </v-btn>

              <v-btn
                  v-if="item.status_name === 'paused'"
                  icon v-bind="attrs" v-on="{on, ...onMenu, ...attrsMenu}">
                <v-icon color="red">mdi-pause</v-icon>
              </v-btn>
            </span>

        </template>
        <span>
        <span v-if="item.status_name === 'active'">Stop</span>
        <span v-if="item.status_name === 'paused'">Start</span>
        </span>
      </v-tooltip>
    </template>

    <v-card>
      <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
        <v-card>
          <v-card-title class="red">
            <span class="headline">API Error</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p class="text-h6">{{ errorMessage }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-title :class="item.status_name === 'active' ? 'red' : 'green' ">
        <span
            v-if="item.status_name === 'active'"
            class="headline">Stop campaign ?</span>
        <span
            v-if="item.status_name === 'paused'"
            class="headline">Start campaign ?</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline"><b>{{ item.campaign_id }}</b> :  {{ item.name }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onUpdate" :loading="loading">
          Yes
        </v-btn>
      </v-card-actions>

    </v-card>

  </v-menu>
</template>


<script>
import api from "@/utils/api";

export default {
  name: "StartStopMenu",
  props: {
    item: {
      default: {}
    }
  },
  data() {
    return {
      menu: false,
      loading: false,
      isVisibleValidationErrorDialog: false,
      errorMessage: ''
    }
  },
  methods: {
    async onUpdate() {
      this.loading = true

      const status = this.item.status_name === 'active' ? 'stop' : 'start'
      const data = {
        network: 'mgid',
        client_id: this.item.client_id,
        campaign_id: this.item.campaign_id,
        status
      }

      try {
        const r = await api.optimizationUpdateCampaignStatus(data)
        if (!r.data.error) {
          this.menu = false
          this.$emit('update', true)
          this.loading = false
        }  else {
          console.error(r)
          this.errorMessage = r.data.message
          this.isVisibleValidationErrorDialog = true
          this.loading = false
        }
      } catch (e) {
        console.error(e)
        this.errorMessage = JSON.stringify(e, null, 2)
        this.isVisibleValidationErrorDialog = true
        this.loading = false
      }

    }
  }
}
</script>


<style scoped>

</style>